<script src="components/sweetalert/dist/sweetalert.min.js"></script>
<script>
import Swal from 'sweetalert2'
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    Swal,
  },
  FormataMsg(notification) {
    let Msg = ''
    Spinner.EndLoad()
    switch(notification.alert_type)
    {
      case 'success':
        Msg = {
         icon: 'success',
         //title: notification.message,
         html: notification.message,
         showConfirmButton: false,
         timer: 2000,
         customClass: {
         confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
       }
       Swal.fire(Msg)
      break;

      case 'error':
        Msg = {
         title: notification.title != null && notification.title != '' ? notification.title : 'Erro!',
         //text: notification.message,
         html: notification.message,
         icon: 'error',
         customClass: {
         confirmButton: 'btn btn-primary',
        },
         buttonsStyling: false,
       }
       Swal.fire(Msg)
      break;

      case 'attention':
        Msg = {
         title: notification.title != null && notification.title != '' ? notification.title : 'Atenção!',
         //text: notification.message,
         html: notification.message,
         icon: 'warning',
         customClass: {
         confirmButton: 'btn btn-primary',
        },
         buttonsStyling: false,
       }
       Swal.fire(Msg)
      break;

      case 'info':
        Msg = {
         title: notification.title != null && notification.title != '' ? notification.title : 'Informativo!',
         //text: notification.message,
         html: notification.message,
         icon: 'info',
         customClass: {
         confirmButton: 'btn btn-primary',
        },
         buttonsStyling: false,
       }
       Swal.fire(Msg)
      break;

       case 'question':
        Msg = {
         title: notification.title != null && notification.title != '' ? notification.title : '',
         //text: notification.message,
         html: notification.message,
         icon: 'question',
         customClass: {
         confirmButton: 'btn btn-primary',
        },
         buttonsStyling: false,
       }
       Swal.fire(Msg)
      break;
    }

  },
}

</script>
