<script src="components/sweetalert/dist/sweetalert.min.js"></script>

<script>
import Swal from 'sweetalert2'

export default {
  StarLoad() {
    let Msg = ''
        Msg = {
         html: 'Por favor aguarde ... ⏳',
         showSpinner: true,
         icon: '',
         customClass: {
         confirmButton: 'btn btn-primary',
        },
         buttonsStyling: false,
         allowOutsideClick: false,
       }
       Swal.fire(Msg)
       Swal.showLoading()
  },
  EndLoad() {
    Swal.close()
  },
  components: {
    Swal,
  },
}

</script>
